body {
    -webkit-font-smoothing: antialiased;
    -moz-font-smoothing: grayscale;
    background-color: #f8f8f8;
    font-size: medium;
  }

  .projectItem {
    display: flexbox;
    height: auto;
    width: auto;
    max-width: 250px;
  }

  .ui.header {
    margin-top: 8%;
    margin-bottom: 1em;
    text-align: center;
  }

  .top_div {
    margin-top: 8%;
    margin-bottom: 1em;
  }  

  .ui.inverted.segment {
    background-color: #777;
  }

  .ui.inverted.borderless.stackable.menu{
    flex: initial;
    background-color: #133A7C;
    border-radius: 3px;
    flex-wrap: wrap;
    margin-bottom: 1em; 
  }
  
  .ui.borderless.inverted.menu {
    flex: initial;
    background-color: #133A7C;
    border-radius: 10px;
    flex-wrap: wrap;
    margin-bottom: 1em;
    
  }

  .ui.borderless.inverted.menu .active.item {
    background-color: #133A7C;
  }
  
  span.sub {
    color: rgba(0, 0, 0, 0.6);
  }
  
  .ui.tablet.computer.only.padded.grid {
    position: absolute;
    z-index: 8;
    width: 100%;
    margin-top: 1rem;
  }

  .last_div {
    margin-bottom: 7em;
  }

  .ProjectCard {
    width: 320px;
    height: 415px;
  }
  
  @media only screen and (max-width: 767px) {
    .ui.ui.borderless.inverted.menu{
      margin-bottom: 1em;
    }
    .ui.header.container {
      margin-top: 3em;
      margin-bottom: 1em;
      text-align: center;
    }
    .top_div {
      margin-top: 10%;
      margin-bottom: 2em;
    }
    .last_div {
      margin-top: 1em;
      margin-bottom: 7em;
    }
    .ui.grid.stackable {
      margin-bottom: 7em;
    }
  }